import React from "react";
import PropTypes from "prop-types";


import baffle from 'baffle';
import Item from '../Menu/Item';
import Expand from '../Menu/Expand';

class Hero extends React.Component {
  constructor(props) {
    super(props);
    const {scrollToContent, backgrounds, theme} = props;

  }

  static propTypes = {
    scrollToContent: PropTypes.func.isRequired,
    backgrounds: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };

  componentDidMount() {

    baffle('#title', {
      characters: "¿¼¾¡˧˩ˤ˫˞šïĲŧ"
    }).start().reveal(1200, 600);

    baffle('#subtitle', {
      characters: "¿¼¾¡˧˩ˤ˫˞"
    }).start().reveal(1200, 1200);

    baffle('#telephone', {
      characters: "123456789"
    }).start().reveal(1200, 1800);

    baffle('#email', {
      characters: "¿¼¾¡˧˩ˤ˫˞"
    }).start().reveal(1200, 1900);
  }

  componentDidUpdate(prevProps) {
    if (
        this.props.path !== prevProps.path ||
        this.props.fixed !== prevProps.fixed ||
        this.props.screenWidth !== prevProps.screenWidth ||
        this.props.fontLoaded !== prevProps.fontLoaded
    ) {
      if (this.props.path !== prevProps.path) {
        this.closeMenu();
      }
      this.hideOverflowedMenuItems();
    }
  }

  getRenderedItems = () => {
    const itemList = this.itemList.current;
    return Array.from(itemList.children);
  };

  hideOverflowedMenuItems = () => {
    const PADDING_AND_SPACE_FOR_MORELINK = this.props.screenWidth >= 1024 ? 60 : 0;

    const itemsContainer = this.itemList.current;
    const maxWidth = itemsContainer.offsetWidth - PADDING_AND_SPACE_FOR_MORELINK;

    this.setState({ hiddenItems: [] }); // clears previous state

    const menu = this.renderedItems.reduce(
        (result, item) => {
          item.classList.add("item");
          item.classList.remove("hideItem");

          const currentCumulativeWidth = result.cumulativeWidth + item.offsetWidth;
          result.cumulativeWidth = currentCumulativeWidth;

          if (!item.classList.contains("more") && currentCumulativeWidth > maxWidth) {
            const link = item.querySelector("a");

            item.classList.add("hideItem");
            item.classList.remove("item");
            result.hiddenItems.push({
              to: link.getAttribute("data-slug"),
              label: link.text
            });
          }
          return result;
        },
        { visibleItems: [], cumulativeWidth: 0, hiddenItems: [] }
    );

    this.setState(prevState => ({ hiddenItems: menu.hiddenItems }));
  };

  toggleMenu = e => {
    e.preventDefault();

    if (this.props.screenWidth < 1024) {
      this.renderedItems.map(item => {
        const oldClass = this.state.open ? "showItem" : "hideItem";
        const newClass = this.state.open ? "hideItem" : "showItem";

        if (item.classList.contains(oldClass)) {
          item.classList.add(newClass);
          item.classList.remove(oldClass);
        }
      });
    }

    this.setState(prevState => ({ open: !prevState.open }));
  };

  closeMenu = e => {
    //e.preventDefault();

    if (this.state.open) {
      this.setState({ open: false });
      if (this.props.screenWidth < 1024) {
        this.renderedItems.map(item => {
          if (item.classList.contains("showItem")) {
            item.classList.add("hideItem");
            item.classList.remove("item");
          }
        });
      }
    }
  };

  render() {

    return (
        <React.Fragment>
          <div className="hero">
            <div className="container">
              <div className="row align-items-center">
                <div className="md-8 text-center ">
                  <div className="text-wrapper">
                    <h1 id="title" className="title">Vladimir Lukyanov</h1>
                    <h2 id="subtitle" className="subtitle">Software Engineer</h2>
                    <a id="telephone" href="tel:+79037944400" className="telephone">+79037944400</a>
                    <a id="email" href="mailto:vladimir@liikyanov.com"
                       className="email">vladimir@liikyanov.com</a>

                    <div className="text-left">
                      <a className="btn btn-primary" href="work-here"
                         data-hover="read more"><span>Download CV</span></a>
                    </div>
                  </div>
                </div>
                <div className="md-8 text-center">
                  <div className="photo-wrapper">
                    <img src="http://oleggert.ru/wp-content/uploads/2018/01/oleggert_33.jpg"
                         className="photo"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }
}

export default Hero;

//#c4984f 