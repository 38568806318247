import PropTypes from 'prop-types';
import React from 'react';
import {graphql} from 'gatsby';
import {ThemeContext} from '../layouts';
import Blog from '../components/Blog';
import Hero from '../components/Hero';
import Seo from '../components/Seo';

import {ResponsiveBar} from '@nivo/bar';

class IndexPage extends React.Component {
  separator = React.createRef();

  scrollToContent = e => {
    this.separator.current.scrollIntoView({block: 'start', behavior: 'smooth'});
  };

  render() {
    const {
      data: {
        posts: {edges: posts = []},
        bgDesktop: {
          resize: {src: desktop},
        },
        bgTablet: {
          resize: {src: tablet},
        },
        bgMobile: {
          resize: {src: mobile},
        },
        site: {
          siteMetadata: {facebook},
        },
      },
    } = this.props;

    const backgrounds = {
      desktop,
      tablet,
      mobile,
    };

    return (
        <React.Fragment>
          <ThemeContext.Consumer>
            {theme => (
                <Hero scrollToContent={this.scrollToContent}
                      backgrounds={backgrounds} theme={theme}/>
            )}
          </ThemeContext.Consumer>


          <div className="work-experience">
            <div className="container">
              <div className="row">
                <div className="xs-16 text-center">
                  <h2 className="title">
                    Professional Experience
                  </h2>
                </div>
                <div className="xs-16 graph-wrapper">

                  <ResponsiveBar
                      data={[
                        {
                          'country': 'Other',
                          'work': 4,
                        },
                        {
                          'country': 'Tinkoff bank',
                          'work': 1.5,
                        },
                        {
                          'country': 'Toptal',
                          'work': 1,
                        },
                        {
                          'country': 'UpWork',
                          'work': 5,
                        },
                      ]}
                      layout="horizontal"
                      keys={[
                        'work',
                      ]}
                      indexBy="country"
                      margin={{
                        'top': 10,
                        'right': 50,
                        'bottom': 80,
                        'left': 120,
                      }}
                      padding={0.3}
                      colorBy="id"
                      colors={['#f1f1f1']}
                      borderColor="inherit:darker(1.6)"
                      axis={{
                        textColor: '#fff',
                        fontSize: '14px',
                        tickColor: '#fff',
                      }}
                      axisBottom={{
                        'orient': 'bottom',
                        'tickSize': 5,
                        'tickPadding': 5,
                        'tickRotation': 0,
                        'legend': 'continuous work experience',
                        'legendPosition': 'middle',
                        'legendOffset': 50,
                      }}
                      axisLeft={{
                        'orient': 'left',
                        'tickSize': 5,
                        'tickPadding': 5,
                        'tickRotation': 0,
                        'legend': 'companies',
                        'legendPosition': 'middle',
                        'legendOffset': -100,
                      }}
                      labelSkipWidth={12}
                      labelSkipHeight={12}
                      labelTextColor="inherit"
                      animate={true}
                      motionStiffness={90}
                      motionDamping={15}
                      theme={{
                        'tooltip': {
                          'container': {
                            'fontSize': '14px',
                          },
                        },
                        'labels': {
                          'textColor': '#f1f1f1',
                        },
                        axis: {
                          textColor: '#fff',
                          fontSize: '14px',
                          tickColor: '#fff',
                          legendColor: '#f1f1f1',
                          legendFontSize: '14px',
                          ticks: {
                            text: {
                              fill: '#ffffff',
                            },
                          },
                        },
                        grid: {
                          stroke: '#f1f1f1',
                          strokeWidth: 1,
                        },
                      }}
                  />

                </div>
              </div>
            </div>
          </div>

          <div className="compass">
            <div className="container">
              <div className="row">
                <div className="xs-16">

                  123

                </div>
              </div>
            </div>

            <video preload="auto" loop muted autoPlay="autoPlay"
                   poster="/static/_img/background-poster.png" id="bg_video"
                   className="video_bg_water">
              <source src="/static/_img/main-video.webm" type="video/webm"/>
              <source src="/static/_img/main-video.mp4" type="video/mp4"/>
            </video>

          </div>

          <div className="features">
            <div className="container">
              <div className="row">

                <div className="md-8">
                  <div className="inner to-right">
                    <img src="/static/_img/1.png" className="feature_image"/>
                  </div>
                </div>

                <div className="md-8">
                  <div className="inner">
                    <h3 className="title">Career Highlights</h3>
                    <div className="feature first">
                      <ul className="list">
                        <li className="item">Versatile verbal and written
                          communication skills with a proven ability to drive
                          progress towards organizational goals while displaying
                          dedication, flexibility, and management skills.
                        </li>
                        <li className="item">Assertive and enthusiastic when
                          working in teams of all sizes; possessing a rigorous
                          work ethic and a broad knowledge of software
                          development and optimization.
                        </li>
                      </ul>
                    </div>
                    <div className="feature second">
                      <ul className="list">
                        <li className="item">Highly detail-oriented with strong
                          organizational skills and refined analytical abilities
                          to break down a problem into its root causes, identify
                          key drivers, and propose sound alternatives.
                        </li>
                        <li className="item">Self-starter, who enjoys challenges
                          and will see complex projects through from inception
                          to completion regardless of external challenges to
                          achieve customer satisfaction and retention.
                        </li>
                      </ul>
                    </div>
                    <div className="feature third">
                      <ul className="list">
                        <li className="item">Excellent problem resolution skills
                          coupled with an ability to analyze emergent business
                          problems and opportunities, and take responsibility
                          for their formulation and execution.
                        </li>
                        <li className="item">Exemplified leadership skills by
                          working to train, mentor and empower peers and junior
                          employees through workflow delegation and regular
                          performance appraisals.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>


          <div className="promo">
            <div className="container">
              <div className="row">
                <div className="xs-16">

                  123

                </div>
              </div>
            </div>

            <video preload="auto" loop muted autoPlay="autoPlay"
                   poster="/static/_img/background-poster.png" id="bg_video"
                   className="video_bg_water">
              <source src="/static/_img/main-video.webm" type="video/webm"/>
              <source src="/static/_img/main-video.mp4" type="video/mp4"/>
            </video>

          </div>

          <div className="features">
            <div className="container">
              <div className="row">

                <div className="md-8">
                  <div className="inner">
                    <h3 className="title">Professional Summary</h3>
                    <p>Innovative full-stack software engineering professional
                      offering 15+ years of experience across the full software
                      development lifecycle from concept to delivery of robust
                      applications. Expertise in various advanced development
                      methodologies, tools and processes for the design and
                      launch of software solutions. Recognized for exceptional
                      troubleshooting skills with an ability to quickly find
                      problems, analyze code, research alternatives, and
                      engineer cost-effective solutions. Strong background in
                      coding, web standards, and open source. Experience with a
                      wide range of software libraries, tools, and languages.
                      Spearheaded end-to-end project lifecycles, mobilizing
                      resources and teams effectively to complete projects on
                      time and under budget. Able to communicate with a diverse
                      range of stakeholders to secure buy-in. </p>
                  </div>
                </div>
                <div className="md-8">
                  <div className="inner image">
                    <img src="/static/_img/2.png" className="feature_image"/>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="technologies">
            <div className="container">
              <div className="row text-center">
                <div className="xs-16">
                  123
                </div>
              </div>
            </div>
          </div>

          <div class="contacts">
            <div class="container">
              <div class="row text-center">
                <div class="xs-16">
                  <h5 class="title">Have more questions?</h5>
                  <p>Send email and get reply – simple and easy.</p>
                  <div>
                    <a href="#" class="btn btn-success">Contact us!</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </React.Fragment>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

/* hero-background
<div className="features">
  <div className="container">
    <div className="row">

      <div className="md-8">
        <div className="inner to-right">
          <img src="/static/_img/1.png" className="feature_image"/>
        </div>
      </div>

      <div className="md-8">
        <div className="inner">
          <h3 className="title">Ключевые характеристики</h3>
          <div className="feature first">
            <div className="title">Порядочность</div>
            <div className="desc">Выполнение профессионального долга и
              соблюдение
              морально-этических норм не позволяют сделать
              работу некачественно, низкосортно и бестактно.
            </div>
          </div>
          <div className="feature second">
            <div className="title">Исполнительность</div>
            <div className="desc">Поставленные задачи необходимо
              выполнять в срок,
              но
              прежде, требуется провести оценку трудозатрат
              и необходимых ресурсов. Это выполнимо и обязательно для
              всех
              сотрудников.
            </div>
          </div>
          <div className="feature third">
            <div className="title">Честность</div>
            <div className="desc">Чаще мы слышим приятную ложь, чем
              правду,
              повсеместно
              – в быту, на работе, в семье. Это неразделимые
              вещи, требующие трезвости ума и открытости в отношениях.
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div> */